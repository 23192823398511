import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">Riwayat Hidup</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Pendidikan
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-laptop qualification__icon"></i>
            Pengalaman
          </div>
        </div>

        {/* Education */}
        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Universitas Darma Persada</h3>
                <span className="qualification__subtitle">
                  S1 Teknologi Informasi
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2019 - 2023
                </div>
                <div className="qualification__clock">
                  <i className="uil uil-clock-eight"></i> Sep - Nov
                </div>
                <div className="qualification__gpa">
                  <i className="uil uil-star"></i> GPA 3.71 / 4.00
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">SMK Negeri 2 Kota Bekasi</h3>
                <span className="qualification__subtitle">
                  Rekayasa Perangkat Lunak
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2014 - 2017
                </div>
                <div className="qualification__clock">
                  <i className="uil uil-clock-eight"></i> Jun - Jul
                </div>
                <div className="qualification__gpa">
                  <i className="uil uil-star"></i> GPA 85.0 / 100.0
                </div>
              </div>
            </div>

            {/* <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Development</h3>
                <span className="qualification__subtitle">
                  Spain - Institute
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2018 - 2020
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">UX Expert</h3>
                <span className="qualification__subtitle">
                  Spain - Institute
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2017 - 2018
                </div>
              </div>
            </div>*/}
          </div> 

          {/* Experience */}
          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Quality Assurance</h3>
                <span className="qualification__subtitle">
                  Binar Academy
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2023
                </div>
                <div className="qualification__clock"> 
                  <i className="uil uil-clock-eight"></i> Mar - Apr
                </div>
                <div className="qualification__type">
                  <i className="uil uil-notebooks"></i> Bootcamp
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Frontend Web & React Developer</h3>
                <span className="qualification__subtitle">Dicoding Indonesia</span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2022
                </div>
                <div className="qualification__clock"> 
                  <i className="uil uil-clock-eight"></i> Agu - Des
                </div>
                <div className="qualification__type">
                  <i className="uil uil-notebooks"></i> Studi Independen
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">IT Support</h3>
                <span className="qualification__subtitle">PT Jalantol Lingkarluar Jakarta</span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2015
                </div>
                <div className="qualification__clock"> 
                  <i className="uil uil-clock-eight"></i> Sep - Des
                </div>
                <div className="qualification__type">
                  <i className="uil uil-notebooks"></i> Magang
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
