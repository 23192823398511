import React, { useState, useEffect } from "react";
import "./home.css";
import Social from "./Social";
import Data from "./Data";
import ScrollDown from "./ScrollDown";
import profileImage from "../../assets/prof3.png";
import { Skeleton } from "antd";

const Home = () => {
  // # Skeleton
  const [imageLoaded, setImageLoaded] = useState(false);
  // useEffect(() => {
  //   setImageLoaded(true);
  // }, []);

  useEffect(() => {
    const img = new Image();
    img.src = profileImage;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <section className="home section" id="home">
      <div className="home__container container grid">
        <div className="home__content grid">
          <Social />
          <div className="home__img">
            {imageLoaded ? 
              ( <img src={profileImage} alt="Profile" /> )
              : 
              ( <Skeleton active={true} size="large" round={true}/> )
            }
            {/* <img src={profileImage} alt=""/> */}
          </div>
          <Data />
        </div>
        <ScrollDown />
      </div>
    </section>
  );
};

export default Home;
