import React from "react";

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
        <i className="bx bx-award about__icon"></i>

        <h3 className="about__title">Pengalaman</h3>
        <span className="about__subtitle">1 Tahun</span>
      </div>

      <div className="about__box">
        <i className="bx bxl-github about__icon"></i>

        <h3 className="about__title">Project</h3>
        <span className="about__subtitle">5+ Dibuat</span>
      </div>

      <div className="about__box">
        <i className="bx bxl-javascript about__icon"></i>

        <h3 className="about__title">Sertifikasi</h3>
        <span className="about__subtitle">10+ Digital</span>
      </div>
    </div>
  );
};

export default Info;
