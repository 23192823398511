import React from "react";
import { Tilt } from "react-tilt";

const defaultOptions = {
	reverse:        false,  // reverse the tilt direction
	max:            0,     // max tilt rotation (degrees)
	perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
	scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
	speed:          2000,   // Speed of the enter/exit transition
	transition:     true,   // Set a transition on enter/exit.
	axis:           null,   // What axis should be disabled. Can be X or Y.
	reset:          true,    // If the tilt effect has to be reset on exit.
	easing:         "cubic-bezier(.2, .8, .3, 1)",    // Easing on enter/exit.
}

const WorksItem = ({ item }) => {
  return (
    <Tilt options={defaultOptions}>
    <div className="work__card" key={item.id}>
      <div ></div>
      <img src={item.image} alt={item.title} className="work__img" />
      <h3 className="work__title">{item.title}</h3>

      {/* <a href="https://github.com/insomniagung/" className="work__button" target="_blank" rel="noreferrer">
        Repo <i className="bx bxl-github work__button-icon"></i>
      </a> 
      <a href="https://github.com/insomniagung/" className="work__button" target="_blank" rel="noreferrer">
        Demo <i className="bx bx-right-arrow-alt work__button-icon"></i>
      </a> */}
      <div className="work__description">{item.stack}</div>

      <div className="work__button">
        <a href={item.repo} target="_blank" rel="noreferrer">
          <i className="bx bxl-github work__button-logo"></i> Repo
        </a>
        &nbsp;/&nbsp;
        <a href={item.demo} target="_blank" rel="noreferrer">
          Demo <i className="bx bxs-chevron-right work__button-icon"></i>
        </a>
      </div>
    </div>
    </Tilt>
  );
};

export default WorksItem;
