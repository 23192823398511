import Img01 from "../../assets/certif/01.jpg";
import Img02 from "../../assets/certif/02.jpg";
import Img03 from "../../assets/certif/03.jpg";
import Img04 from "../../assets/certif/04.jpg";
import Img05 from "../../assets/certif/05.jpg";
import Img06 from "../../assets/certif/06.jpg";
import Img07 from "../../assets/certif/07.jpg";
import Img08 from "../../assets/certif/08.jpg";
import Img09 from "../../assets/certif/09.jpg";
import Img10 from "../../assets/certif/10.jpg";
import Img11 from "../../assets/certif/11_capstone.jpg";
import Img12 from "../../assets/certif/12_qa.jpg";
import Img13 from "../../assets/certif/13.jpg";
import Img14 from "../../assets/certif/14.jpg";

export const Data = [  
    {
        id: 14,
        image: Img14,
        title: "Belajar Pemrograman Python (Basic)",
        lang: "Python, Jupyter, Unittest"
    },
    {
        id: 13,
        image: Img13,
        title: "Belajar Pemrograman JavaScript (Basic)",
        lang: "JavaScript, Jest, NPM"
    },
    {
        id: 12,
        image: Img12,
        title: "Quality Assurance",
        lang: "Test Case & Scenario, Bug Report, Postman"
    },
    {
        id: 11,
        image: Img11,
        title: "Penghargaan Best Capstone Team Project",
        lang: "Achievement"
    },
    {
        id: 8,
        image: Img08,
        title: "Belajar Membuat Aplikasi React (Intermediate)",
        lang: "HTML, CSS, Javascript dengan React"
    },
    {
        id: 7,
        image: Img07,
        title: "Belajar Membuat Aplikasi React (Basic)",
        lang: "HTML, CSS, Javascript dengan React"
    },
    {
        id: 9,
        image: Img09,
        title: "Belajar Frontend Web Developer (Expert)",
        lang: "HTML, CSS, Javascript"
    },
    {
        id: 6,
        image: Img06,
        title: "Belajar Frontend Web Developer (Intermediate)",
        lang: "HTML, CSS, Javascript"
    },
    {
        id: 5,
        image: Img05,
        title: "Belajar Frontend Web Developer (Basic)",
        lang: "HTML, CSS, Javascript"
    },
    {
        id: 4,
        image: Img04,
        title: "Belajar Dasar Pemrograman Web",
        lang: "HTML, CSS, Javascript"
    }, 
    {
        id: 3,
        image: Img03,
        title: "Belajar Git dengan Github",
        lang: "Version Control"
    },
    {
        id: 10,
        image: Img10,
        title: "Belajar Meniti Karir Software Developer",
        lang: "Softskill Programming"
    },
    {
        id: 2,
        image: Img02,
        title: "Belajar Menjadi Pengembang Software",
        lang: "Logical Thinking",
    },
    {
        id: 1,
        image: Img01,
        title: "Belajar Programming Logic 101",
        lang: "Logical Thinking"
    },    
]