// import Work1 from "../../assets/work1.jpg";
// import Work2 from "../../assets/work2.jpg";
// import Work3 from "../../assets/work3.jpg";
// import Work4 from "../../assets/work4.jpg";
// import Work5 from "../../assets/work5.jpg";
// import Work6 from "../../assets/work6.jpg";

// export const projectsData = [
//   {
//     id: 1,
//     image: Work1,
//     title: "Web Desktop",
//     category: "web",
//   },
//   {
//     id: 2,
//     image: Work2,
//     title: "Android Movie",
//     category: "android",
//   },
//   {
//     id: 3,
//     image: Work3,
//     title: "Brand Desktop",
//     category: "desktop",
//   },
//   {
//     id: 4,
//     image: Work4,
//     title: "Android Movie",
//     category: "android",
//   },
//   {
//     id: 5,
//     image: Work5,
//     title: "Web Desktop",
//     category: "web",
//   },
//   {
//     id: 6,
//     image: Work6,
//     title: "Web Desktop",
//     category: "web",
//   }
// ];

import Employee from "../../assets/thumb/employee/1.png";
import rentCar from "../../assets/thumb/rentCar/1.png";
import kaiSen from "../../assets/thumb/kaiSen/1.png";
import smileApps from "../../assets/thumb/smileApp/1.png";

export const projectsData = [
  {
    id: 1,
    image: kaiSen,
    title: "KAI Sentiment Analysis",
    category: "web",
    repo: "http://github.com/insomniagung/kai-sen",
    demo: "http://kai-sen.streamlit.app/",
    stack: "Python, Jupyter, Streamlit, Deta Base",
  },
  {
    id: 2,
    image: smileApps,
    title: "Smile App",
    category: "web",
    repo: "http://github.com/insomniagung/smileapps/",
    demo: "http://smile-apps.netlify.app/",
    stack: "JavaScript, React, REST API",
  },
  {
    id: 3,
    image: rentCar,
    title: "Rent Car",
    category: "desktop",
    repo: "http://github.com/insomniagung/Car-Rental-Desktop/",
    demo: null,
    stack: "Java, NetBeans, MySQL",
  },
  {
    id: 4,
    image: Employee,
    title: "Employee Recruitment",
    category: "web",
    repo: "http://github.com/insomniagung/EmployeeRecruitmentSystems-v3/",
    demo: "http://sispeg.epizy.com/",
    stack: "Bootstrap, PHP Native, MySQL",
  },
  
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  // {
  //   name: "android",
  // },
  {
    name: "desktop",
  }
];
