import React, { useState } from "react";
import "./services.css";

const Services = () => {
  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">Pengalaman Melayani</span>

      <div className="services__container container grid">
        {/* ===== Service 1 ===== */}
        <div className="services__content">
          <div>
            <i className="uil uil-brackets-curly services__icon"></i>
            <h3 className="services__title">
              Web <br /> Developer
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(1)}>
            Lihat Detail
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          {/* <div className="services__modal"> */}
          <div
            className={
              toggleState === 1
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                className="uil uil-times services__modal-close"
                onClick={() => toggleTab(0)}
              ></i>

              <h3 className="services__modal-title">
                Frontend Web & React Developer
              </h3>
              <p className="services__modal-description">
                Memberikan pengalaman terbaik dengan web yang responsif dan
                optimal.
              </p>
              <hr className="services__modal-description-line" />

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Mengubah dan mengelola aplikasi menggunakan HTML, CSS, dan
                    JavaScript sesuai dengan spesifikasi.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Menerapkan Storage, Components, Webpack, serta AJAX untuk
                    mendapatkan data dinamis dari Web API.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Mengaplikasikan React sebagai User Interface pada Web yang
                    dibangun.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Mengelola project dan berkolaborasi antar developer
                    menggunakan Github.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* ===== Service 2 ===== */}
        <div className="services__content">
          <div>
            <i className="uil uil-cube services__icon"></i>
            <h3 className="services__title">
              Quality <br /> Assurance
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(2)}>
            Lihat Detail
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          {/* <div className="services__modal"> */}
          <div
            className={
              toggleState === 2
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                className="uil uil-times services__modal-close"
                onClick={() => toggleTab(0)}
              ></i>

              <h3 className="services__modal-title">Quality Assurance</h3>
              <p className="services__modal-description">
                Melakukan pengujian software berdasarkan flow yang diberikan.
              </p>
              <hr className="services__modal-description-line" />

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Melakukan uji positif dan negatif aplikasi untuk Test Case &
                    Test Scenario.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Menyediakan Bug Report berdasarkan pengujian Test Case &
                    Test Scenario.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Melakukan pengujian request dan response API menggunakan
                    Postman.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* ===== Service 3 ===== */}
        {/* <div className="services__content">
          <div>
            <i className="uil uil-edit services__icon"></i>
            <h3 className="services__title">
              Visual <br /> Designer
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(3)}>
            View More 
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">

              <i className="uil uil-times services__modal-close" onClick={() => {toggleTab(0)}}></i>

              <h3 className="services__modal-title">
                Visual Designer
              </h3>
              
              <p className="services__modal-description">
                Service with 1 years of experience. Providing quality work to companies.
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    I develop the user interface.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Web page development.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    I position your company brand.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Design and mockups of products for companies.
                  </p>
                </li>
              </ul>

            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Services;
