import React from "react";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import Services from "./components/services/Services";
import Qualification from "./components/qualification/Qualification";
import Work from "./components/work/Work";
// import Testimonial from "./components/testimonial/Testimonial";
import Certificate from "./components/certificate/Certificate";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";

const App = () => {
  return (
    <>
      <div>
        <header>
          <Header />
        </header>

        <main className="main">
          <Home />
          <About />
          <Skills />
          <Certificate />
          <Services />
          <Qualification />
          <Work />
          {/* <Testimonial /> */}
          <Contact />
        </main>

        <Footer />
        <ScrollUp />
      </div>
    </>
  );
};

export default App;
