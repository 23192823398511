import React, { useEffect, useState } from "react";
import { projectsNav } from "./Data";
import { projectsData } from "./Data";
import WorksItem from "./WorksItem";

const Works = () => {
  const [item, setItem] = useState({ name: "all" });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  // === added ===
  const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 2;
  const itemsPerPage = 4;
  // === added ===

  useEffect(() => {
    if (item.name === "all") {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }

    // === added ===
    // Reset current page when switching category
    setCurrentPage(1);
    // === added ===

  }, [item]);

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  // === added ===
  // Calculate the total number of pages
  const totalPages = Math.ceil(projects.length / itemsPerPage);
  // Display the current page items
  const currentProjects = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // === added ===

  return (
    <div>
      <div className="work__filters">
        {projectsNav.map((item, index) => {
          return (
            <span
              onClick={(e) => {
                handleClick(e, index);
              }}
              className={`${active === index ? "active-work" : ""} work__item`}
              key={index}
            >
              {item.name}
            </span>
          );
        })}
      </div>

      {/* <div className="work__container container grid">
        {projects.map((item) => {
          return <WorksItem item={item} key={item.id} />;
        })}
      </div> */}

      {/* === added === */}
      {totalPages > 1 && (
        <div className="pagination">
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            <i className='bx bx-caret-left'></i>
          </button>
          <span>{`Slide ${currentPage} dari ${totalPages}`}</span>
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            <i className='bx bx-caret-right'></i>
          </button>
        </div>
      )}

      <div className="work__container container grid">
        {currentProjects.map((item) => {
          return <WorksItem item={item} key={item.id} />;
        })}
      </div>
      {/* === added === */}

    </div>
  );
};

export default Works;
